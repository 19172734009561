function togglePasswordVisibility() {
  const inputElement = this.parentElement.getElementsByTagName("input")[0];
  const toggleElementClassList = this.classList;

  toggleElementClassList.contains("visible")
    ? toggleElementClassList.remove("visible")
    : toggleElementClassList.add("visible");

  inputElement.type === "password"
    ? (inputElement.type = "text")
    : (inputElement.type = "password");
}

const addTogglePasswordVisibility = function() {
  const passwordInputs = document.querySelectorAll(".js-password-placeholder");

  if (passwordInputs) {
    passwordInputs.forEach(e => {
      const toggleElement = e.querySelector(".js-password-show");
      toggleElement.addEventListener("click", togglePasswordVisibility);
    });
  }
};

export default addTogglePasswordVisibility;
