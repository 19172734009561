import countries from './countries.js'
import { getParcelBySearch } from "./uldkApi.js";
import getAddressByXY from "./uugApi.js";

const vcache = {
  'inp-voivodeship': {},
  'inp-powiat': {},
  'inp-municipality': {},
  'inp-city': {},
  'inp-street': {},
  'inp-house_no': {},
}

const fOrd = [
  'voivodeship',
  'powiat',
  'municipality',
  'city',
  'street',
  'house_no',
  'local_no',
  'postal_code',
  'post'
];

function _clearSels(root, sel) {
  var after = false;
  for (var i in fOrd) {
    if (after)
      root.find('.inp-' + fOrd[i]).val('');
    if (fOrd[i] == sel)
      after = true;
  }
}

function _setAddr($el, house_no) {
  var k = _buildKey($el.parent().parent(), 'house_no');
  if (k in vcache['inp-house_no']) {
    var addr = vcache['inp-house_no'][k];
    if ('pktKodPocztowy' in addr[0])
      $el.parent().parent().find('.inp-postal_code').val(addr.pktKodPocztowy);
  }
}

function _buildKey(parent, psel) {
  var arr = [];
  for (var i in fOrd) {
    arr.push(parent.find('.inp-' + fOrd[i]).val());
    if (fOrd[i] == psel)
      break;
  }
  var k = arr.join('#');
  if (k[k.length - 1] == '#')
    k = k.slice(0, -1);
  return k;
}


function _makeLowerCaseWords(str) {
  var arr = str.toLowerCase().split(' ');
  for (var i in arr) {
    const w = arr[i];
    arr[i] = w.charAt(0).toUpperCase() + w.slice(1);
  }
  return arr.join(' ');
}


var __lcache = {}


function _load(root, sel, psel, url, pm, ppm, pNazwa, pNum, pId, cb = null) {
  var k = _buildKey(root, psel);
  if (!(k in vcache['inp-' + psel]))
    return;
  var jAdms = vcache['inp-' + psel][k];
  $(root).find('.inp-' + sel).css('background-color', '#F8F8F8');
  $(root).find('.inp-' + sel).data('autoComplete')._dd.noResultsText = 'Wczytywanie...';
  for (var ji in jAdms) {
    var jAdm = jAdms[ji];
    var _lck = url + jAdm[pNum] + '/' +  jAdm[pId];
    if (_lck in __lcache) {
      const data = __lcache[_lck];
      for (var i in data[pm]) {
        const jAdm = data[pm][i][ppm];
        if (jAdm[pNazwa] == jAdm[pNazwa].toUpperCase()) {
          jAdm[pNazwa] = _makeLowerCaseWords(jAdm[pNazwa]);
        }
        const __k = k + '#' + jAdm[pNazwa]
        if (!(__k in vcache['inp-' + sel]))
          vcache['inp-' + sel][__k] = []
        vcache['inp-' + sel][__k].push(jAdm);
      }
    } else {
      $.ajax({
        url: url + jAdm[pNum] + '/' +  jAdm[pId] + '/skr.json',
        dataType: 'json',
        async: false,
        success: function(data) {
          __lcache[_lck] = data;
          for (var i in data[pm]) {
            const jAdm = data[pm][i][ppm];
            if (jAdm[pNazwa] == jAdm[pNazwa].toUpperCase()) {
              jAdm[pNazwa] = _makeLowerCaseWords(jAdm[pNazwa]);
            }            
            const __k = k + '#' + jAdm[pNazwa];
            if (!(__k in vcache['inp-' + sel]))
              vcache['inp-' + sel][__k] = []
            vcache['inp-' + sel][__k].push(jAdm);
          }      
        }
      });
    }
  }
  $(root).find('.inp-' + sel).css('background-color', '#FFF');
  $(root).find('.inp-' + sel).data('autoComplete')._dd.noResultsText = 'Brak wyników';
  if (cb)
    cb();
  else
    $(root).find('.inp-' + sel).autoComplete('show');
}


function _checkPostCode(parent) {
  if (parent.find('.inp-house_no').val().length) {
    var k = _buildKey(parent, 'house_no');
    if (k in vcache['inp-house_no']) {
      var addr = vcache['inp-house_no'][k];
      if ('pktKodPocztowy' in addr) {
        parent.find('.inp-postal_code').val(addr.pktKodPocztowy);
        return;
      }
    }    
  }
  var arr = [];
  for (var i in fOrd) {
    arr.push(parent.find('.inp-' + fOrd[i]).val());
  }
  if (arr.length < 4)
    return;
  $.getJSON('https://nominatim.openstreetmap.org/search?format=json&countrycodes=pl&q=' + arr.join(' '), function (data) {    
    if (data.length == 1) {
      var resArr = data[0].display_name.split(',');
      var postCode = resArr[resArr.length - 2].trim();
      if ((postCode.length == 6) && ((postCode[2] == '-') || (postCode[2] == ' ')))
        parent.find('.inp-postal_code').val(postCode.replace(' ', '-'));
    }
  });
}


function _autoEnabled(el) {
  var $p = $(el).parent().parent().find('.inp-country');
  if ($p.length) {
    return ($p.val().trim().toLowerCase() == 'polska');
  }
  return true;
}

function _initAutoc(root, sel, nsel, url, pm, ppm, pNazwa, pNum, pId) {
  $(root).find('.inp-' + sel).each(function () {
    $(this).autoComplete({
      resolver: 'custom',
      minLength: 0,
      events: {
        search: function (qry, callback, el) {
          if (!_autoEnabled(el)) {
            callback([]);
            return;
          }
          var basek = _buildKey($(el).parent().parent(), sel).toLowerCase();          
          var keys = Object.keys(vcache['inp-' + sel]); 
          var ret = [];
          for (var i in keys) {
            if (!(keys[i].toLowerCase().includes(basek)))
              continue;
            var k = keys[i].split('#').pop();            
            if ((qry.length == 0) || (k.search(new RegExp(qry, "i")) >= 0)) {
              ret.push(k);
            }
          }
          callback(ret);
        }
      }
    }).on('focus', function () {
      $(this).autoComplete('show');      
    }).on('autocomplete.select', function (e, item) {
      var parent = $(e.currentTarget).parent().parent();
      _checkPostCode(parent);
      _load(parent, nsel, sel, url, pm, ppm, pNazwa, pNum, pId);      
    }).on('change', function (e) {
      var parent = $(e.currentTarget).parent().parent();
      _checkPostCode(parent);
      _load(parent, nsel, sel, url, pm, ppm, pNazwa, pNum, pId);      
    });
  });
}


function _setFullAddress(parent, addr) {
  var arr = addr.jednostka.replace('{', '').replace('}', '').split(',');
  parent.find('.inp-voivodeship').val(arr[1]);
  parent.find('.inp-powiat').val(arr[2]);
  parent.find('.inp-municipality').val(arr[3]);
  parent.find('.inp-city').val(addr.city);
  if (('code' in addr) && (addr.code))
    parent.find('.inp-postal_code').val(addr.code);
  if (('street' in addr) && (addr.street))
    parent.find('.inp-street').val(addr.street.replace('ulica ', ''));
  if (('number' in addr) && (addr.number))
    parent.find('.inp-house_no').val(addr.number); 
}

function _setPartialAddress(parent, addr) {
  var arr = addr.jednostka.replace('{', '').replace('}', '').split(',');
  parent.find('.inp-voivodeship').val(arr[1]);
  parent.find('.inp-powiat').val(arr[2]);
  parent.find('.inp-municipality').val(arr[3]);
  parent.find('.inp-city').val(addr.city);
}


function initLotAutocomplete() {
  $("#add-form-item").on("click", handleNewForm);
  $('.autocomplete-wrapper').each(function () {
    initForm($(this));
  });  
  $('.lot-form-item').each(function () {
    initForm($(this));
  });    
}


function initForm(root) {
 
  $(root).find('.form-group').removeClass('filled-form-group');
  $(root).find('.form-group .bootstrap-autocomplete.dropdown-menu.show').remove();

  $(root).find('.form-group input[type=text], .form-group input[type=search]').each(function () {
    $(this).addClass('inp-' + this.id.split('-').pop().replace('mailing_', ''))
      .data('noresults-text', 'Brak wyników')
      .attr('type', 'search')
      .attr('autocomplete', 'off');    
  });

  $(root).find('.inp-country').autoComplete({
    resolver: 'custom',
    minLength: 0,
    events: {
      search: function (qry, callback) {
        var ret = [];
        for (var i in countries) {
          var cn = countries[i]['name_pl'];
          if ((qry.length == 0) || (cn.search(new RegExp(qry, "i")) >= 0))
            ret.push(cn);
        }
        callback(ret);      
      }
    }
  }).on('focus', function () {
    $(this).autoComplete('show');
  }).on('autocomplete.select', function (e, item) {
    if (item.toLowerCase() == 'polska') {
      $(root).find('input[type="search"]').not('.inp-country').parent().removeClass('autoc-hidden');
    } else {
      $(root).find('input[type="search"]').not('.inp-country').parent().addClass('autoc-hidden');
    }
  }).on('change', function (e) {
    var item = $(e.currentTarget).val(); 
    if (item.toLowerCase() == 'polska') {
      $(root).find('input[type="search"]').not('.inp-country').parent().removeClass('autoc-hidden');
    } else {
      $(root).find('input[type="search"]').not('.inp-country').parent().addClass('autoc-hidden');
    }
  });

  if (Object.keys(vcache['inp-voivodeship']).length == 0) {
    $.getJSON('https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/woj.json', function (data) {

      for (var i in data.jednAdms) {
        var jAdm = data.jednAdms[i].jednAdm;
        vcache['inp-voivodeship'][jAdm.wojNazwa] = [ jAdm ];
      }
      
      $(root).find('.inp-voivodeship').each(function () {
        var $el = $(this);
        if ($el.val().length) {
          _load($el.parent().parent(), 'powiat', 'voivodeship', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/pow/', 'jednAdms', 'jednAdm', 'powNazwa', 'wojIIPPn', 'wojIIPId', function () {            
            $el.parent().parent().find('.inp-powiat').each(function () {
              var $el = $(this);
              if ($el.val().length) {
                _load($el.parent().parent(), 'municipality', 'powiat', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/gmi/', 'jednAdms', 'jednAdm', 'gmNazwa', 'powIIPPn', 'powIIPId', function () {
                  $el.parent().parent().find('.inp-municipality').each(function () {
                    var $el = $(this);
                    if ($el.val().length) {
                      _load($el.parent().parent(), 'city', 'municipality', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/miejsc/', 'miejscowosci', 'miejscowosc', 'miejscNazwa', 'gmIIPPn', 'gmIIPId', function () {
                        $el.parent().parent().find('.inp-city').each(function () {
                          var $el = $(this);
                          if ($el.val().length) {
                            _load($el.parent().parent(), 'street', 'city', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/ul/', 'ulice', 'ulica', 'ulNazwaGlowna', 'miejscIIPPn', 'miejscIIPId', function () {
                              $el.parent().parent().find('.inp-street').each(function () {
                                var $el = $(this);
                                if ($el.val().length) {
                                  _load($el.parent().parent(), 'house_no', 'street', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/adr/ul/', 'adresy', 'adres', 'pktNumer', 'ulIIPPn', 'ulIIPId', function () {
                                  });
                                }
                              });
                            });
                          }
                        });
                      });
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
  }

  $(root).find('.inp-voivodeship').autoComplete({
    resolver: 'custom',
    minLength: 0,
    events: {
      search: function (qry, callback, el) {
        if (!_autoEnabled(el)) {
          callback([]);
          return;
        }
        var keys = Object.keys(vcache['inp-voivodeship']);
        var ret = [];
        for (var i in keys) {
          if ((qry.length == 0) || (keys[i].search(new RegExp(qry, "i")) >= 0))
            ret.push(keys[i]);
        }
        callback(ret);      
      }
    }
  }).on('focus', function () {
    $(this).autoComplete('show');
  }).on('autocomplete.select', function (e, item) {
    _load($(e.currentTarget).parent().parent(), 'powiat', 'voivodeship', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/pow/', 'jednAdms', 'jednAdm', 'powNazwa', 'wojIIPPn', 'wojIIPId');
  }).on('change', function (e) {
    _load($(e.currentTarget).parent().parent(), 'powiat', 'voivodeship', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/pow/', 'jednAdms', 'jednAdm', 'powNazwa', 'wojIIPPn', 'wojIIPId');
  });

   _initAutoc(root, 'powiat', 'municipality', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/gmi/', 'jednAdms', 'jednAdm', 'gmNazwa', 'powIIPPn', 'powIIPId');
   _initAutoc(root, 'municipality', 'city', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/miejsc/', 'miejscowosci', 'miejscowosc', 'miejscNazwa', 'gmIIPPn', 'gmIIPId');
   _initAutoc(root, 'city', 'street', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/ul/', 'ulice', 'ulica', 'ulNazwaGlowna', 'miejscIIPPn', 'miejscIIPId');
   _initAutoc(root, 'street', 'house_no', 'https://mapy.geoportal.gov.pl/wss/service/SLN/guest/sln/adr/ul/', 'adresy', 'adres', 'pktNumer', 'ulIIPPn', 'ulIIPId');

   $(root).find('.inp-house_no').autoComplete({
    resolver: 'custom',
    minLength: 0,
    events: {
      search: function (qry, callback, el) {
        if (($('.inp-country').length) && ($('.inp-country').val().trim().toLowerCase() != 'polska')) {
          callback([]);
          return;
        }
        var keys = Object.keys(vcache['inp-house_no']);
        var ret = [];
        for (var i in keys) {
          var k = keys[i].split('#').pop();
          if ((qry.length == 0) || (k.search(new RegExp(qry, "i")) >= 0))
              ret.push(k);
        }
        callback(ret);
      }
    }
  }).on('focus', function () {
    $(this).autoComplete('show');
  }).on('change', function (e) {
    var v = $(e.currentTarget).val();    
    _checkPostCode($(e.currentTarget).parent().parent());
  }).on('autocomplete.select', function (e, item) {
    _checkPostCode($(e.currentTarget).parent().parent());
  });
  
  $(root).find('.inp-registration_unit').change(function () {
    var $el = $(this);
    var v = $el.val();
    if (v.length == 0)
      return;
    var $p = $el.parent().parent();
    for (var i in fOrd) {
      if ($p.find('.inp-' + fOrd[i]).val().length) {
        return;
      }          
    }
    $(root).find('input[type="search"]:not(.inp-registration_unit)').css('background-color', '#F8F8F8');
    getParcelBySearch(v).then(function (r) {
      if ((r.results) && (r.results.length)) {
        var parc = r.results[0];
        if ((parc) && ('plotParcel' in parc))
          $p.find('.inp-registration_lot_no').val(parc.plotParcel);
        if ((parc) && ('plotPrecinct' in parc))
          $p.find('.inp-registration_precit').val(parc.plotPrecinct);
       
        var plotGeom = parc.plotGeom
          .replace("SRID=4326;", "")
          .replace("POLYGON((", "")
          .replace("))", "");

        getAddressByXY(plotGeom, "POLYGON").then(function (rr) {
          if ((rr) && ('results' in rr) && (rr.results) && (Object.keys(rr.results).length)) {
            var k = Object.keys(rr.results).shift();
            var addr = rr.results[k];
            _setFullAddress($p, addr);
            $(root).find('input[type="search"]').css('background-color', '#FFF');
          } else {
            const pt = plotGeom.split(',').shift().split(' ');
            const lng = pt[0];            
            const lat = pt[1];       
            getAddressByXY({ x: lng, y: lat }, "POINT").then(function (rrr) {              
              if ((rrr) && ('results' in rrr) && (rrr.results) && (Object.keys(rrr.results).length)) {
                var k = Object.keys(rrr.results).shift();
                var addr = rrr.results[k];
                _setPartialAddress($p, addr);
              }
              $(root).find('input[type="search"]').css('background-color', '#FFF');
            });
          }
        });
      }
    });        
  }).change();
  
  $(root).find('.inp-postal_code').change(function () {
    var v = $(this).val();
    if ((v) && (v.length == 6) && (v[2] == '-')) {
      var allempty = true;
      for (var k in vcache) {
        if ($(root).find('.' + k).val().length) {
          allempty = false;
          break;
        }
      }
      if (allempty) {
        $.getJSON('https://nominatim.openstreetmap.org/search?format=json&countrycodes=pl&addressdetails=1&limit=1&postalcode=' + v, function (data) {
          if ((data) && (data.length) && (data[0]) && (data[0].display_name)) {
            var addr = data[0].address;
            var woj = addr.state.replace('województwo ', '');
            if ('city' in addr) {              
              $(root).find('.inp-voivodeship').val(woj).change();
              $(root).find('.inp-powiat').val(addr.city).change();
              $(root).find('.inp-municipality').val(addr.city).change();
              $(root).find('.inp-city').val(addr.city).change();
            } else if ('village' in addr) {
              var pow = addr.county.replace('powiat ', '');
              var gm = addr.municipality.replace('gmina ', '');
              $(root).find('.inp-voivodeship').val(woj).change();
              $(root).find('.inp-powiat').val(pow).change();
              $(root).find('.inp-municipality').val(gm).change();
              $(root).find('.inp-city').val(addr.village).change();
            }
          }
        });
      }      
    }
  });
}




function handleNewForm() {
  const newestForm = $(".form-item").last();
  initForm(newestForm);
}

export default initLotAutocomplete;
