const uldkApiUrl = "https://uldk.gugik.gov.pl/";
import fetchWithTimeout from "./fetchWithTimeout.js";

const requestedParcelData =
  "id,numer,powiat,gmina,geom_wkt,voivodeship,teryt,region,parcel,jednostka_ewidencyjna";

function parseApiResponse(response) {
  if (!response || response.substr(0, 2) === "-1") {
    return {
      count: 0,
      results: [],
    };
  }
  const parsedResults = [];
  const lines = response.split("\n").filter(v => v !== "");
  for (let i = 0; i < lines.length; i++) {
    const fields = lines[i].split("|");
    parsedResults[i] = [];
    for (let j = 0; j < fields.length; j++) {
      parsedResults[i][j] = fields[j];
    }
  }
  const [count, ...results] = parsedResults;

  const formattedResults = results.map(result => {
    const [
      plotId,
      plotNumber,
      plotCounty,
      plotCommune,
      plotGeom,
      plotVoivodeship,
      plotTerytId,
      plotPrecinct,
      plotParcel,
      plotRegistrationUnit,
    ] = result;

    return {
      plotId,
      plotNumber,
      plotCounty,
      plotCommune,
      plotGeom,
      plotVoivodeship,
      plotTerytId,
      plotPrecinct,
      plotParcel,
      plotRegistrationUnit,
    };
  });

  return {
    count,
    results: formattedResults,
  };
}

async function getParcelByIdOrNr({ id, precinct, number }) {
  if (!id && (!precinct || !number)) {
    return {
      count: 0,
      results: [],
    };
  }
  const searchString = id ? id : `${precinct} ${number}`;

  try {
    const response = await fetchWithTimeout(
      `${uldkApiUrl}?request=GetParcelByIdOrNr&id=${searchString}&result=${requestedParcelData}&srid=4326`,
      { timeout: 15000 },
    );
    const bodyText = await response.text();
    return parseApiResponse(bodyText);
  } catch (error) {
    const errorObj = { msg: "error GetParcelByIdOrNr", error };
    console.error(errorObj);
    throw errorObj;
  }
}

async function getParcelBySearch(text) {
  if (!text) {
    return {
      count: 0,
      results: [],
    };
  }
  const searchString = text;

  try {
    const response = await fetchWithTimeout(
      `${uldkApiUrl}?request=GetParcelByIdOrNr&id=${searchString}&result=${requestedParcelData}&srid=4326`,
      { timeout: 15000 },
    );
    const bodyText = await response.text();
    return parseApiResponse(bodyText);
  } catch (error) {
    const errorObj = { msg: "error GetParcelByIdOrNr", error };
    console.error(errorObj);
    throw errorObj;
  }
}


async function getParcelByXY({ x, y }) {
  if (!x || !y) {
    return {
      count: 0,
      results: [],
    };
  }

  try {
    const response = await fetchWithTimeout(
      `${uldkApiUrl}?request=GetParcelByXY&xy=${x},${y}&result=${requestedParcelData}&srid=4326`,
      { timeout: 15000 },
    );
    const bodyText = await response.text();
    return parseApiResponse(bodyText);
  } catch (error) {
    const errorObj = { msg: "error GetParcelByXY", error };
    console.error(errorObj);
    throw errorObj;
  }
}

export { getParcelByIdOrNr, getParcelByXY, getParcelBySearch };
