export function manage_custom_answers_buttons() {
  const customAnswersSel = ".questionnaire__custom-answers";
  const removeButtonSel = ".custom-answer__remove-answer";
  const answerSel = ".custom-answer";
  const firstAnswerClass = "custom-answer--first";

  const disableRemoveButton = ($this, state) => {
    $this.find(removeButtonSel).prop("disabled", state);
  };

  const emptyInput = $this => {
    $this.find("input").val("");
  };

  $(".custom-answer__add-answer").click(function() {
    const $firstAnswer = $(this)
      .parents(customAnswersSel)
      .find(`.${firstAnswerClass}`);
    const $answerCopy = $firstAnswer.clone();

    $answerCopy.removeClass(firstAnswerClass);
    disableRemoveButton($answerCopy, false);
    emptyInput($answerCopy);
    $(this)
      .parents(".questionnaire__custom-answers")
      .append($answerCopy);
  });

  $(answerSel)
    .find("input")
    .keyup(function() {
      const $thisInput = $(this);
      const $thisAnswer = $thisInput.parents(answerSel);
      const disableState = !$thisInput.val() ? true : false;
      disableRemoveButton($thisAnswer, disableState);
    });

  $(customAnswersSel).on("click", removeButtonSel, function() {
    const $removedAnswer = $(this).parents(answerSel);

    if ($removedAnswer.hasClass(firstAnswerClass)) {
      const removed_answer = $removedAnswer.get()[0];
      const next_answer = removed_answer.nextElementSibling;
      if (next_answer) {
        // remove this and set next as "first"
        $removedAnswer.remove();
        next_answer.classList.add(firstAnswerClass);
      } else {
        // prevent removing last answer
        emptyInput($removedAnswer);
        disableRemoveButton($removedAnswer, true);
      }
    } else {
      $removedAnswer.remove();
    }
  });
}

export function manage_custom_predefined_answers() {
  // selecting predefined answer in single-custom-answer-question
  [
    ...document.querySelectorAll("#questionnaire .js-predefined-answer"),
  ].forEach(element => {
    const form_group = element.closest(".form-group");
    const text_input = form_group.querySelector("textarea");
    element.addEventListener("click", e => {
      e.preventDefault();
      text_input.value = element.innerText;
    });
  });
}
