import { validatePasswords } from "./registration.js";

function validatePasswordChange() {
  const changePasswordElement = document.getElementById("password-change-form");
  const resetPasswordSubmitButton = document.querySelector(".js-login-submit");

  function validatePasswordResetFunction(e) {
    e.preventDefault();

    if (changePasswordElement) {
      const password1 = document.getElementsByName("new_password1")[0];
      const password2 = document.getElementsByName("new_password2")[0];
      const passwordValidationErrorElement = document.querySelector(
        ".js-passwords-validation-error",
      );

      passwordValidationErrorElement.innerText = "";

      const passwordsValidation = validatePasswords(
        password1.value,
        password2.value,
      );

      passwordValidationErrorElement.innerText = passwordsValidation.message;

      if (passwordsValidation.test) {
        changePasswordElement.submit();
      } else {
        return;
      }
    }
  }
  if (changePasswordElement && resetPasswordSubmitButton) {
    changePasswordElement.onsubmit = validatePasswordResetFunction;
  }
}

export default validatePasswordChange;
