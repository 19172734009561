const countFormsToDelete = function(params) {
  const totalInputCountElement = document.getElementById(
    params.totalFormsInputElementId,
  );
  if (!totalInputCountElement) {
    return;
  }

  const deleteFormElements = document.getElementsByClassName(
    params.deleteFormElementsClassName,
  );
  const totalFormsInputElement = document.getElementById(
    params.totalFormsInputElementId,
  );

  let formsToDeleteCount = 0;
  const formItems = document.getElementsByClassName(params.formItemsClassName);

  Array.from(deleteFormElements).forEach(child => {
    child.lastElementChild.checked ? (formsToDeleteCount += 1) : "";
  });
  totalFormsInputElement.value = formItems.length;
};

const addCountFormsToDeleteListener = function(params) {
  const totalInputCountElement = document.getElementById(
    params.totalFormsInputElementId,
  );

  if (!totalInputCountElement) {
    return;
  }
  const deleteFormElements = document.getElementsByClassName(
    params.deleteFormElementsClassName,
  );
  const totalFormsInputElement = document.getElementById(
    params.totalFormsInputElementId,
  );

  if (!deleteFormElements && !totalFormsInputElement) {
    return;
  }
  Array.from(deleteFormElements).forEach(el => {
    el.lastElementChild.addEventListener("click", function() {
      countFormsToDelete(params);
    });
  });
};

export { addCountFormsToDeleteListener, countFormsToDelete };
