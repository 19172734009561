/*
 * Countdown until expiration of user trial
 */

import { polish_plural } from "./utils.js";

const countdown = () => {
  if ($(".countdown").length > 0) {
    const format_number = number => {
      return ("0" + number).slice(-2);
    };

    const one_second = 1000;
    const one_minute = 60 * one_second;
    const one_hour = 60 * one_minute;
    const one_day = 24 * one_hour;

    const expiration_time = $(".countdown").attr("data-expiration");
    const current_time = new Date().getTime();

    const remained_time = expiration_time - current_time;

    if (remained_time > 0) {
      const remained_time_in_hours = Math.floor(
        Math.abs(remained_time / one_hour),
      );
      const remained_time_in_minutes = Math.floor(
        Math.abs(remained_time / one_minute),
      );
      const remained_time_in_seconds = Math.floor(
        Math.abs(remained_time / one_second),
      );

      const remained_days = Math.floor(Math.abs(remained_time / one_day));
      const remained_hours = remained_time_in_hours - remained_days * 24;
      const remained_minutes =
        remained_time_in_minutes - remained_time_in_hours * 60;
      const remained_seconds =
        remained_time_in_seconds - remained_time_in_minutes * 60;

      $(".countdown .countdown__days").html(format_number(remained_days));
      $(".countdown .countdown__hours").html(format_number(remained_hours));
      $(".countdown .countdown__minutes").html(format_number(remained_minutes));
      $(".countdown .countdown__seconds").html(format_number(remained_seconds));

      $(".countdown__days-label-pl").text(
        polish_plural("dzień", "dni", "dni", remained_days),
      );
      $(".countdown__days-label-en").text(
        polish_plural("day", "days", "days", remained_days),
      );

      $(".countdown__hours-label--pl").text(
        polish_plural("godzina", "godziny", "godzin", remained_hours),
      );
      $(".countdown__hours-label--en").text(
        polish_plural("hour", "hours", "hours", remained_hours),
      );
    }
  }
};

export default countdown;
