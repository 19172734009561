function validateEmail(email) {
  let errorMessage = "";
  email = email || "";
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailTest = re.test(String(email).toLowerCase());
  emailTest
    ? ""
    : (errorMessage = "Wpisany tekst nie jest poprawnym adresem email.");
  return { test: emailTest, message: errorMessage };
}

function compareEmail(email1, email2) {
  let errorsMessage = "";
  email1 === email2
    ? ""
    : (errorsMessage += "Obydwa pola e-mail muszą mieć tę samą wartość");
  return { test: !errorsMessage, message: errorsMessage };
}

function validateUserName(userName) {
  userName = userName || "";
  const userNameRegExp = RegExp("^[A-Za-z0-9/@/./+/-/_ ]+$");
  let errorsMessage = "";
  userNameRegExp.test(userName)
    ? ""
    : (errorsMessage += "Nazwa użytkownika zawiera niedozwolone znaki.\n");
  userName.length <= 150
    ? ""
    : (errorsMessage += "Nazwa użytkownika może mieć maksymalnie 150 znaków.");
  return { test: !errorsMessage, message: errorsMessage };
}

function validateFirstLastName(userName) {
  userName = userName || "";
  const userNameRegExp = RegExp("^[-0-9a-zA-Z\u00C0-\u024F\u1E00-\u1EFF/./_ ]*$");
  let errorsMessage = "";
  userNameRegExp.test(userName)
    ? ""
    : (errorsMessage += "Nazwa użytkownika zawiera niedozwolone znaki.\n");
  return { test: !errorsMessage, message: errorsMessage };
}

function validatePasswords(password1, password2) {
  password1 = password1 || "";
  password2 = password2 || "";
  let errorMessage = "";
  password1 && password2
    ? ""
    : (errorMessage += "Pole hasła nie może być puste.");
  password1 === password2
    ? ""
    : (errorMessage += "Wpisane hasła muszą być identyczne.");
  return { test: !errorMessage, message: errorMessage };
}

function validateRegistrationForm() {
  const registrationForm = document.getElementById("registration_form");
  const registrationSubmitButton = document.querySelector(
    ".js-login-button__register",
  );

  function validateRegistrationFormFunction(e) {
    e.preventDefault();

    const userName = document.getElementsByName("username")[0];
    const userEmail = document.getElementsByName("email")[0];
    const password1 = document.getElementsByName("password1")[0];
    const password2 = document.getElementsByName("password2")[0];

    const userNameValidationErrorElement = document.querySelector(
      ".js-user-name-validation-error",
    );
    const emailValidationErrorElement = document.querySelector(
      ".js-email-validation-error",
    );
    const emailCompareErrorElement = document.querySelector(
      ".js-email-compare-error",
    );
    const passwordValidationErrorElement = document.querySelector(
      ".js-password-validation-error",
    );

    userNameValidationErrorElement.innerText = "";
    passwordValidationErrorElement.innerText = "";
    emailValidationErrorElement.innerText = "";

    const passwordsValidation = validatePasswords(
      password1.value,
      password2.value,
    );
    const userNameValidation = validateEmail(userName.value);
    const emailValidation = validateEmail(userEmail.value);
    const emailCompare = compareEmail(userName.value, userEmail.value);

    userNameValidationErrorElement.innerText = userNameValidation.message;
    passwordValidationErrorElement.innerText = passwordsValidation.message;
    emailValidationErrorElement.innerText = emailValidation.message;
    emailCompareErrorElement.innerText = emailCompare.message;

    if (
      passwordsValidation.test &&
      userNameValidation.test &&
      emailValidation.test &&
      emailCompare
    ) {
      registrationForm.submit();
    } else {
      return;
    }
  }

  if (registrationForm && registrationSubmitButton) {
    registrationForm.onsubmit = validateRegistrationFormFunction;
  }
}

export {
  validateRegistrationForm,
  validateEmail,
  validateUserName,
  validateFirstLastName,
  validatePasswords,
};
