const togglePopupWindow = function(
  openButtonClass,
  popupWindowClass,
  popupHideWindowClass,
  closeButtonClass,
) {
  const openButton = document.querySelector("." + openButtonClass);
  const popupWindow = document.querySelector("." + popupWindowClass);
  const closeButtons = document.querySelectorAll("." + closeButtonClass);
  const popupClassHidden = popupHideWindowClass;

  const togglePopup = function() {
    const popupClassList = popupWindow.classList;
    popupClassList.contains(popupClassHidden)
      ? popupClassList.remove(popupClassHidden)
      : popupClassList.add(popupClassHidden);
    return false;
  };

  if (openButton && popupWindow && closeButtons.length) {
    openButton.addEventListener("click", togglePopup);
    closeButtons.forEach(e => e.addEventListener("click", togglePopup));
  }
};

export default togglePopupWindow;
