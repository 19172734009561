const adjust_epb5 = function() {
  const investors_extra_data_box = $(".investors_extra_data");
  const proxies_extra_data_box = $(".proxies_extra_data");
  const proxy_chooser_box = $(".proxy_chooser");
  const epb5_data_box = $(".epb5_data");

  const add_epb5 = $('#add-epb5').is(":checked");
  const epb_who = $('.epb-who input:checked').attr('value');
  const proxy_id = $('.proxy_chooser select[name="proxy_for_epb5"]').val();
  
  if (add_epb5) {
    epb5_data_box.removeClass('d-none');
    if (!($('.auto-pb5 .form-check-input').is(':checked'))) {
      $('.auto-pb5 .form-check-input').attr('checked', true);
      $('.auto-pb5 .form-check-label').addClass('checked');
    }
  }
  else {
    epb5_data_box.addClass('d-none');
  }

  if (epb_who == 'proxy') {
    investors_extra_data_box.addClass('d-none');
    if (proxy_chooser_box.length) {
      proxy_chooser_box.removeClass('d-none');
      $(".proxy_extra_data").each(function() {
	  if ($(this).hasClass('proxy_' +  proxy_id + '_extra_data')) {
              $(this).removeClass('d-none');
	  }
	  else {
              $(this).addClass('d-none');
	  }
      });
    }
    proxies_extra_data_box.removeClass('d-none');
  }
  else {
    investors_extra_data_box.removeClass('d-none');
    if (proxy_chooser_box.length) {
      proxy_chooser_box.addClass('d-none');
      $(".proxy_extra_data").each(function() {
          $(this).addClass('d-none');
      });
    }
    proxies_extra_data_box.addClass('d-none');
  }
};

const disable_or_enable_extra_data_fields = function() {
  $(this).parents('.epb-who').find('input').attr('checked', false);
  $(this).parents('.epb-who').find('label').removeClass('checked');
  $(this).attr('checked', 'checked');
  $(this).parent().find('label').addClass('checked');
  adjust_epb5();
};

const handle_attachment_fields = element => {
  const $element = element ? $(element) : null;

  if ($(".epb-who :radio").length)
  {
    const $whoRadioFields = $element
      ? $element.find(".epb-who :radio")
      : $(".epb-who :radio");
    $whoRadioFields.change(disable_or_enable_extra_data_fields);
    $whoRadioFields.each(function() {
      if ($(this).is(':checked')) {
        $(this).change();
      }
    });
  }

  if ($("select[name=proxy_for_epb5]").length)
  {
    const $proxy_for_epb5 = $("select[name=proxy_for_epb5]");
    $proxy_for_epb5.change(adjust_epb5);
  }

  $('#add-epb5').change(adjust_epb5);

  adjust_epb5();
  
  $('#has_other_attachments').change(function () {
    if ($(this).is(':checked')) {
      $('.form-item__attachment').show();      
      $('#add-form-item').show();
    } else if (!($(this).is(':checked'))) {
      $('.form-item__attachment').hide();
      $('#add-form-item').hide();
      //$('.js-delete-file-button').click(); 
    }
  }).change();
};

export default handle_attachment_fields;
