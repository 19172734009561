const polish_plural = (
  singular_nominativ,
  plural_nominativ,
  plural_genitive,
  number,
) => {
  if (number === 1) {
    return singular_nominativ;
  } else if (
    number % 10 >= 2 &&
    number % 10 <= 4 &&
    (number % 100 < 10 || number % 100 >= 20)
  ) {
    return plural_nominativ;
  } else {
    return plural_genitive;
  }
};

export { polish_plural };
