import { polish_plural } from "./utils.js";

const add_number_to_form_headers = () => {
  const question_label_selector = ".questionnaire__form .form-group > label";
  let question_number_level1 = 0;
  let question_number_level2 = 0;
  $(question_label_selector).each(function(i) {
    if ($(this).children('p').length == 0) return;
    const is_subquestion = $(this).hasClass('subquestion');
    if (is_subquestion) {
        question_number_level2++;
    }
    else {
	question_number_level1++;
	question_number_level2 = 0;
    }
    const $label = $(this).find("p");
    let txt = '';
    if (question_number_level2) {
      txt = question_number_level1 + "." + question_number_level2;
      $(this).parent().addClass('subquestion');
    }
    else {
      txt = question_number_level1;
    }
    $label
      .parent()
      .prepend(
        '<span class="font-main-color question-number">' +
	      txt +
          ". </span>",
      );
    // move help icon after label
    $(this)
      .next(".questionnaire__help-icon")
      .appendTo($label);
  });
};

const update_question_numbers = () => {
  const $visible_form_groups = $(".questionnaire__form .requirements-passed");
  const number_of_questions = $visible_form_groups.length;

  $(".number-of-questions").text(number_of_questions);

  $(".number-of-questions--label-pl").text(
    polish_plural("pytanie", "pytania", "pytań", number_of_questions),
  );
  $(".number-of-questions--label-en").text(
    polish_plural("answer", "answers", "answers", number_of_questions),
  );

  let question_number_level1 = 0;
  let question_number_level2 = 0;
  $visible_form_groups.each(function() {
    if ($(this).find('.question-number').length == 0) return;
    const is_subquestion = $(this).children("label").hasClass('subquestion');
    if (is_subquestion) {
        question_number_level2++;
    }
    else {
	question_number_level1++;
	question_number_level2 = 0;
    }
    let txt = '';
    if (question_number_level2) {
      txt = question_number_level1 + "." + question_number_level2 + ". ";
      $(this).addClass('subquestion');
    }
    else {
      txt = question_number_level1 + ". ";
    }
    $(this)
      .find(".question-number")
      .text(txt);
  });
};

export { add_number_to_form_headers, update_question_numbers };
