/*
 * Cookies consent
 */
import Cookies from "js-cookie";

const manage_cookie_notice = () => {
  const $cookies_banner = $(".cookies-banner");
  const cookies_consent = "cookies-consent";

  $(`.${cookies_consent}`).click(function() {
    if (typeof Cookies !== "undefined") {
      Cookies.set(cookies_consent, "true");
    }
    $cookies_banner.slideToggle();
  });

  if (typeof Cookies !== "undefined" && Cookies.get(cookies_consent)) {
    $cookies_banner.hide();
  } else {
    $cookies_banner.slideToggle();
  }
};

export default manage_cookie_notice;
