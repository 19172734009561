const switchContrast = function() {
  const bodyClassList = document.body.classList;
  const contrastSwitchButton = document.getElementById("js-contrast-switch");
  
  if (contrastSwitchButton) {
    const contrastSwitchText = contrastSwitchButton.querySelector("span");
    
    contrastSwitchText.innerText = "Ustaw wysoki kontrast";

    contrastSwitchButton.addEventListener("click", function() {
      if (bodyClassList.contains("high-contrast")) {
        bodyClassList.remove("high-contrast");
        contrastSwitchText.innerText = "Ustaw wysoki kontrast";
        Cookies.remove('highcontrast', { expires: 7, path: '/' })
      } else {
        bodyClassList.add("high-contrast");
        contrastSwitchText.innerText = "Ustaw niski kontrast";
        Cookies.set('highcontrast', true, { expires: 7, path: '/' })        
      }
    });
  }
  
  var c = Cookies.get('highcontrast', { path: '/' });
  if (c == "true") {
    if (!bodyClassList.contains("high-contrast")) {
      bodyClassList.add("high-contrast");
      contrastSwitchText.innerText = "Ustaw niski kontrast";
    }
  }  
};

export default switchContrast;
