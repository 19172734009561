const _add_form_popups = $form => {
  $form.on("submit", function(event) {
    const $target = $($form.attr("data-target"));

    $.ajax({
      type: $form.attr("method"),
      url: $form.attr("action"),
      data: $form.serialize(),

      success(data) {
        $target.find(".modal-body").html(data);
        _add_form_popups($target.find("form[data-async]"));
      },
    });

    event.preventDefault();
  });
};

const add_form_popups = () => {
  return _add_form_popups($("form[data-async]"));
};

export default add_form_popups;
