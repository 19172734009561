const uugApiUrl = "https://services.gugik.gov.pl/uug/";
import fetchWithTimeout from "./fetchWithTimeout.js";

async function parseApiResponse(response) {
  if (!response) {
    return {
      msg: "Can't get response from UUG server.",
    };
  }

  let parsedResponse = {};

  try {
    parsedResponse = await JSON.parse(response);
  } catch (error) {
    const errorObj = { msg: "Error in UUG parsing response.", error };
    throw errorObj;
  }

  return parsedResponse;
}

async function getAddressByXY(geom, geomType) {
  // geomType could be:
  // POINT

  let geometryRequest = null;
  if (geomType === "POINT") {
    if (!geom.x || !geom.y || !geomType) {
      return {
        msg: "Provide lat lang values and geometry.",
      };
    }
    geometryRequest = `POINT(${geom.x} ${geom.y})`;
  }

  // POLYGON
  if (geomType === "POLYGON") {
    if (!geom || !geomType) {
      return {
        msg: "Provide geometry values and geometry.",
      };
    }

    geometryRequest = `POLYGON((${geom}))`;
  }

  try {
    const response = await fetchWithTimeout(
      `${uugApiUrl}?request=GetAddressReverse&location=${geometryRequest}&srid=4326`,
      { timeout: 15000 },
    );
    const bodyText = await response.text();
    const parsedAddressResponse = await parseApiResponse(bodyText);
    return parsedAddressResponse;
  } catch (error) {
    const errorObj = { msg: "Error in GetAddressByXY", error };
    console.error(errorObj);
    throw errorObj;
  }
}

export default getAddressByXY;
