/*
 * Showing and hiding fields on data controller form based on its organisation type
 */

const show_field_mechanism = () => {
  const $orgDataElement = $("#organisation_types");

  if ($orgDataElement.length) {
    let orgData;
    try {
      orgData = JSON.parse($orgDataElement.html());
    } catch (err) {
      console.error({ msg: "error parsing organisation types", err });
    }

    if (orgData) {
      const $orgTypeSelect = $("#id_organisation_type");
      const show_or_hide_fields = () => {
        const selectedOption = $orgTypeSelect.find("option:selected").val();

        // fill organisation type short name
        const shortName = orgData[selectedOption]["short_name"];
        if (orgData[selectedOption]["prefixed"]) {
          $("#id_organisation_type_short_name_prefix").val(shortName);
          $("#id_organisation_type_short_name_suffix")
            .parent(".form-group")
            .hide();
          $("#id_organisation_type_short_name_prefix")
            .parent(".form-group")
            .show();
        } else {
          $("#id_organisation_type_short_name_suffix").val(shortName);
          $("#id_organisation_type_short_name_prefix")
            .parent(".form-group")
            .hide();
          $("#id_organisation_type_short_name_suffix")
            .parent(".form-group")
            .show();
        }

        // surname
        const isOptionPersonRelated = orgData[selectedOption]["person_related"];
        const $surnameField = $("#id_organisation_person_name").parents(
          ".form-group",
        );
        $surnameField.toggle(isOptionPersonRelated);

        // initial capital
        $("#initial_capital_field").toggle(
          orgData[selectedOption].has_initial_capital,
        );

        // krs number
        $("#id_organisation_krs_number")
          .parents(".form-group")
          .toggle(orgData[selectedOption].has_krs_number);

        // krs department
        const display_krs_department =
          orgData[selectedOption].display_krs_department;
        $("#krs_department_field").toggle(display_krs_department);
        $("#krs_department_field_replacement").toggle(!display_krs_department);
      };

      show_or_hide_fields();

      $orgTypeSelect.change(() => {
        show_or_hide_fields();
      });
    }
  }
};

export default show_field_mechanism;
