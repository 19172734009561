/*
 * Handling checking inputs in survey
 */

const manage_survey_form = () => {
  $(document).on("change", ".survey input", function() {
    const $currentField = $(this);
    const $currentLabel = $currentField.parent();
    const $currentFormGroup = $currentField.parents(".form-group");

    const checkIfAllAnswered = () => {
      return $(".form-group").length === $(".survey input:checked").length;
    };

    const markChosenOffer = () => {
      /*
       * if any input with data-offer iodo is checked we recommend "iodo"
       * else if input with data-offer branches is checked we recommend "branches"
       * if not "branches" or "iodo" - we recommend "thematical"
       */

      let recommendedOffer = "thematical";

      $(".survey input:checked").each(function() {
        const checkedOfferData = $(this).data("offer");

        if (checkedOfferData === "iodo") {
          recommendedOffer = checkedOfferData;

          return false; // break
        }

        if (checkedOfferData === "branches") {
          recommendedOffer = checkedOfferData;
        }
      });

      const chosenClass = "offer-column--chosen";

      $(".offer-column").removeClass(chosenClass);
      $(`.offer-column[data-offer=${recommendedOffer}]`).addClass(chosenClass);
    };

    const updateRecommendations = () => {
      const $recommendations = $(".offers");
      const $recommendationsEmpty = $(".offers-empty");

      if (checkIfAllAnswered()) {
        markChosenOffer();

        if ($recommendations.css("display") === "none") {
          $recommendationsEmpty.slideUp(600, () => {
            $recommendations.slideDown(600);
          });
        }
      } else {
        $recommendations.slideUp(300, () => {
          $recommendationsEmpty.slideDown(600);
        });
      }
    };

    if ($currentField.is(":checked")) {
      $currentLabel.addClass("checked");
      $currentFormGroup
        .find("label")
        .not($currentLabel)
        .removeClass("checked")
        .find("input")
        .prop("checked", false);
      updateRecommendations();
    } else {
      $currentLabel.removeClass("checked");
      updateRecommendations();
    }
  });
};

export default manage_survey_form;
