const handleFormsetMaxFieldCount = function(params) {
  const saveButton = document.getElementById(params.saveButtonId);

  const totalFormsInputElement = document.getElementById(
    params.totalFormsInputElementId,
  );
  const maxFormsInputElement = document.getElementById(
    params.maxFormsInputElementId,
  );

  if (saveButton && totalFormsInputElement && maxFormsInputElement) {
    maxFormsInputElement.value = totalFormsInputElement.value;
  }
};

export default handleFormsetMaxFieldCount;
