import { deleteFormEntry } from "./delete_form.js";
import bytesToSize from "./bytesToSize.js";

const handleFileInputs = function() {
  if (!$("#attachments-form-items")) {
    return;
  }

  const $formInputs = $(".form-item.form-item__attachment");

  if (!$formInputs.length) {
    return;
  }

  $formInputs.each(function() {
    const $el = $(this);
    if ($el.data("event-listener-added") === "true") {
      return;
    }
    const $saveButtonElement = $("#save-button");
    const $newFileInputButton = $el.find(".form-item__file-input-button");
    const $removeFileToUploadElement = $el.find(".js-delete-form-button");
    const $deleteUploadedFile = $el.find(".js-delete-file-button");
    const $fileToUploadName = $el.find(".form-item__file-name");
    const $fileToUploadSize = $el.find(".form-item__file-size");
    const $uploadedFileLink = $el.find("a");
    const $fileWrapperContents = $el
      .find(".fieldWrapper.js-form-field-file")
      .contents();

    let $originalFileInputButton;
    if ($el.find(".js-form-field-file")) {
      $originalFileInputButton = $el.find(".js-form-field-file").find("input");
    }

    $deleteUploadedFile.hide();
    $uploadedFileLink.hide();

    if ($uploadedFileLink.length) {
      $fileToUploadName.text("");
      $fileToUploadName.append($uploadedFileLink[0]);
      $uploadedFileLink.show();
      $newFileInputButton.hide();
    }

    $fileWrapperContents.each(function() {
      if (this.nodeName !== "#text") {
        return;
      }
      let textContent = this.textContent;
      textContent = textContent.replace("Teraz:", "");
      textContent = textContent.replace("Zmień:", "");
      this.textContent = textContent;
    });

    $(".fieldWrapper.js-form-field-delete.checkbox").hide();

    function clickOriginalInput() {
      if (!$originalFileInputButton) {
        return;
      }
      $originalFileInputButton.click();
    }

    function handleFileToUpload() {
      $saveButtonElement.trigger("click");
      if (!this.files.length) {
        $fileToUploadName.text("");
        $fileToUploadSize.text("");
        $newFileInputButton.hide();
        return;
      }
      $fileToUploadName.append(this.files[0].name);
      $fileToUploadName.append($uploadedFileLink[0]);
      $uploadedFileLink.show();
      $fileToUploadSize.text(bytesToSize(this.files[0].size));
      $removeFileToUploadElement.hide();
    }

    function removeFileToUpload() {
      $originalFileInputButton.val("");
      $fileToUploadName.text("");
      $fileToUploadSize.text("");
      $newFileInputButton.show();
    }

    if ((($originalFileInputButton.prop("files")) && $originalFileInputButton.prop("files").length) || $uploadedFileLink) {
      $removeFileToUploadElement.hide();
      $deleteUploadedFile.show();
    }

    if ($deleteUploadedFile) {
      $deleteUploadedFile.on("click", deleteFormEntry);
    }
    
    if ($newFileInputButton) {
      $newFileInputButton.on("click", clickOriginalInput);
    }

    if ($originalFileInputButton) {
      $originalFileInputButton.on("change", handleFileToUpload);
    }

    if ($removeFileToUploadElement) {
      $removeFileToUploadElement.on("click", removeFileToUpload);
    }
    $el.data("event-listener-added", "true");
  });
  
};
export default handleFileInputs;
