import {
  add_number_to_form_headers,
  update_question_numbers,
} from "./form_headers.js";
import {
  manage_custom_answers_buttons,
  manage_custom_predefined_answers,
} from "./form_custom_answers.js";

import autosize from "./autosize.js";

var __ter_dict = {}
if (typeof(__ter) != 'undefined') {
  for (var i1 in __ter) {
    const el1 = __ter[i1];
    var textarr = el1.text.split(' ')
    textarr.shift();
    __ter_dict[textarr.join(' ')] = el1.id;
    for (var i1 in el1.children) {
      const el2 = el1.children[i1];
      var textarr = el2.text.split(' ')
      textarr.shift();
      __ter_dict[textarr.join(' ')] = el2.id;
      for (var i2 in el2.children) {
        const el3 = el2.children[i2];
        var textarr = el3.text.split(' ')
        textarr.shift();
        __ter_dict[textarr.join(' ')] = el3.id;
      }
    }
  }
}

var __$tree_inp = null;
var __search_to = false;

const init_ter_tree = function ($element) {
  autosize($element.find('.needs-terr-tree .form-group textarea'));
  $element.find('#terrother').hide();
  $element.find('#terrother').change(function () {
    if (__$tree_inp) {
      var selected = $('#jstree').jstree("get_selected");
      var instance = $('#jstree').jstree(true);
      var texts = [];
      for (var i in selected) {
        var textarr = instance.get_node(selected[i]).text.split(' ')
        var code = textarr.shift();
        if (code != '14_N') {            
          texts.push(textarr.join(' '));
        }
      }
      if (selected.includes('14_N')) {
        var textarr = instance.get_node('14_N').text.split(' ')
        textarr.shift();          
        var oth = $element.find('#terrother input').val();
        if (oth.length) {            
          textarr.push('(' + oth + ')');
        }
        texts.push(textarr.join(' '));
      }
      __$tree_inp.val(texts.join(', '));
      autosize.update(__$tree_inp);
    }
  });
  $element.find('.needs-terr-tree .form-group textarea').attr('readonly', 'readonly').css('background-color', '#FFF').click(function () {
    __$tree_inp = $(this);
    try {
      $('#jstree').off('loaded.jstree');
      $('#jstree').off('click', '.jstree-anchor');
      $('#jstree').off("open_node.jstree");
      $('#jstree').off("close_node.jstree");
      $('#jstree').jstree("destroy");
    } catch (e) {}
    $('#jstree').jstree({
      "plugins" : [ "search", "checkbox" ],
      "checkbox" : {
        "three_state": false,
      },
      "core" : {
        "themes": {
          "dots": false
        },
        'data': __ter
      }}).on("changed.jstree", function (e, data) {
        if (!('node' in data))
          return;
        if (data.node.id == '14_N') {
          if (data.selected.includes('14_N')) {
            $element.find('#terrother').show();
          } else {
            $element.find('#terrother').hide();
            $element.find('#terrother input').val('');
          }
        }
        var texts = [];
        for (var i in data.selected) {
          var textarr = data.instance.get_node(data.selected[i]).text.split(' ')
          var code = textarr.shift();
          if (code != '14_N') {            
            texts.push(textarr.join(' '));
          }
        }
        if (data.selected.includes('14_N')) {
          var textarr = data.instance.get_node('14_N').text.split(' ')
          textarr.shift();          
          var oth = $element.find('#terrother input').val();
          if (oth.length) {            
            textarr.push('(' + oth + ')');
          }
          texts.push(textarr.join(' '));
        }
        __$tree_inp.val(texts.join(', '));
        autosize.update(__$tree_inp);
      });
    $('#jstree').on('loaded.jstree', function () {
        const selected = __$tree_inp.val().split(',');
        $element.find('#terrother').hide();
        for (var i in selected) {
          var t = selected[i].trim();          
          if (t.indexOf("teren niesklasyfikowany") == 0) {
            var arr = t.split("teren niesklasyfikowany (");
            if (arr.length == 2) {
              var oth = arr.pop().slice(0, -1);
              $('#terrother input').val(oth);
              $element.find('#terrother').show();
            }
            t = "teren niesklasyfikowany";
          }
          if (t in __ter_dict) {
            $("#jstree").jstree('select_node', __ter_dict[t]);
          }
        }
        $('#jstree .jstree-open > .jstree-anchor > .jstree-themeicon').removeClass('fa-folder-closed').addClass('fa-folder-open'); 
      });
    $('#jstree').on('click', '.jstree-anchor', function (e) {
        $(this).jstree(true).toggle_node(e.target);
      });
    $('#jstree').on("select_node.jstree", function (e, data) {
        var arr = data.node.id.split('_');
        var c = arr.pop();
        while (arr.length > 1) {      
          arr.pop();                    
          var pid = arr.join('_') + '_' + c.substring(0, arr.length);
          $("#jstree").jstree('deselect_node', pid);
        }
        for (var i in data.node.children_d) {
          $("#jstree").jstree('deselect_node', data.node.children_d[i]);
        }
      });
    $('#jstree').on("open_node.jstree", function (e, data) {
        $('#' + data.node.id + ' > .jstree-anchor > .jstree-themeicon').removeClass('fa-folder-closed').addClass('fa-folder-open');      
      });
    $('#jstree').on("close_node.jstree", function (e, data) {
        $('#' + data.node.id + ' > .jstree-anchor > .jstree-themeicon').removeClass('fa-folder-open').addClass('fa-folder-closed');      
      });
    $('#tree-search').keyup(function () {
        if (__search_to) { clearTimeout(__search_to); }
        __search_to = setTimeout(function () {
          var v = $('#tree-search').val();
          if (v.length)
            $('#jstree').jstree(true).search(v);
          else
            $("#jstree").jstree("clear_search");          
        }, 250);      
      });
    $('#tree-search').change(function () {       
          var v = $('#tree-search').val();
          if (v.length == 0)
            $("#jstree").jstree("clear_search");          
      });
    $('#terModal').modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#terModal').modal('show');        
  });
}


const manage_form = () => {
  /*
   * Showing and hiding questions in forms
   */

  let current_tags_list = [];
  const blocking_tags = [];
  const required_tags = []; // question_id -> list of tags required to display
  const provided_tags = [];

  const reset_tags_list = () => {
    current_tags_list = [];

    if (typeof window.tags_list !== "undefined") {
      current_tags_list.push(...window.tags_list);
    }
  };

  const reset_tags_dependencies = () => {
    if (typeof window.tags_dependencies !== "undefined") {
      for (const question_id in window.tags_dependencies) {
        const dependency = window.tags_dependencies[question_id];

        if (typeof dependency.required_tags !== "undefined") {
          required_tags[question_id] = dependency.required_tags;
        } else {
          required_tags[question_id] = [];
        }

        if (typeof dependency.blocking_tags !== "undefined") {
          blocking_tags[question_id] = dependency.blocking_tags;
        } else {
          blocking_tags[question_id] = [];
        }

        if (typeof dependency.answers !== "undefined") {
          provided_tags[question_id] = dependency.answers;
        } else {
          provided_tags[question_id] = [];
        }
      }
    }
  };

  const requirements_test_passed = (requirements_tags, blockers_tags) => {
    if (typeof current_tags_list !== "undefined") {
      const unique_tags_list = new Set(current_tags_list);

      if (
        blockers_tags.length > 0 &&
        blockers_tags.every(blocking_tag => unique_tags_list.has(blocking_tag))
      ) {
        return false;
      }

      for (const requirements_tag of requirements_tags) {
        if (!unique_tags_list.has(requirements_tag)) {
          return false;
        }
      }
    }

    return true;
  };

  /**
   * compute whether the question is visible (with current set of tags)
   */
  const question_visible = question_id => {
    const question_required_tags = required_tags[question_id];
    const question_blocking_tags = blocking_tags[question_id];
    return requirements_test_passed(
      question_required_tags,
      question_blocking_tags,
    );
  };

  const update_exit_popup = () => {
    let button_type = "button";
    let popup_id = "#exitConfirmation";
    if (
      $(".requirements-passed").has(".checked").length ===
      $(".requirements-passed").length
    ) {
      button_type = "submit";
      popup_id = "";
    }

    $(".exit-button")
      .prop("type", button_type)
      .attr("data-target", popup_id);
  };

  const update_form = (animate = false) => {
    const id_prefix = "id_question_";

    for (const id in required_tags) {
      const current_question_id = "#" + id_prefix + id;
      const $current_question = $(current_question_id)
        .add(`#id_custom_answers_${id}`)
        .parents(".form-group");

      if (question_visible(id)) {
        if (animate === "animate") {
          $current_question.slideDown(300).addClass("requirements-passed");
        } else {
          $current_question.show().addClass("requirements-passed");
        }
      } else {
        if (animate === "animate") {
          $current_question.slideUp(300).removeClass("requirements-passed");
        } else {
          $current_question.hide().removeClass("requirements-passed");
        }
      }
    }

    update_question_numbers();
  };

  const mark_unanswered_questions = () => {
    const answered_questions = [];
    const unanswered_questions = [];
    $(".requirements-passed")
      .filter(":not(.js-form-group--not-required)")
      .each(function() {
        const checked = $(this).find(":has(.checked)").length;
        const user_values = $(this)
          .find("textarea, input")
          .filter(':not([type="radio"], [type="checkbox"])')
          .filter((_, el) => el.value)
          .get();
        if (!checked && !user_values.length) {
          unanswered_questions.push(this);
        } else {
          answered_questions.push(this);
        }
      });

    const url = new URL(window.location.href);
    const final_checks = url.searchParams.get("final_checks");

    if (answered_questions.length > 0 && final_checks == "1") {
      // mark unanswered only if there is at least one answered

      $(unanswered_questions).addClass("unanswered");

      // scroll to first unanswered
      if ($(".unanswered").length > 0) {
        $("html, body").animate(
          {
            scrollTop: $(".unanswered").offset().top,
          },
          600,
        );
      }
    }
  };

  /**
   * add tags emited by checking given answer
   */
  const update_tags_list = (question_id, answer_id) => {
    try {
      const tags_to_add = provided_tags[question_id][answer_id].provided_tags;
      if (tags_to_add.length > 0) {
        current_tags_list.push(...tags_to_add);
      }
    } catch (err) {
      /*
       * Do nothing
       */
    }
  };

  const $inputs = $(
    '.questionnaire__form .form-group input[id^="id_question_"]',
  );

  const check_if_last_answer_is_excluding = $input => {
    if ($input.is('[data-last-answer-is-excluding=""]')) {
      const $this_question_checkboxes = $input.parents(".checkbox").parent();
      const $last_input = $this_question_checkboxes.find(
        ".form-check:last-child input",
      );
      const $all_other_inputs = $this_question_checkboxes.find(
        ".form-check:not(:last-child) input",
      );
      const $custom_answers = $input
        .parents(".form-group")
        .find(".questionnaire__custom-answers .custom-answer");
      const $custom_answer_add_row = $input
        .parents(".form-group")
        .find(".custom-answer__add-answer")
        .closest(".row");

      if ($last_input.is(":checked")) {
        $all_other_inputs.prop("disabled", true);
        $all_other_inputs.prop("checked", false);
        $all_other_inputs.parent().addClass("blocked");
        $all_other_inputs.parent().removeClass("checked");
        $custom_answers.each(function(index) {
          if (index === 0) {
            $(this)
              .find("input")
              .val("");
            $(this)
              .find("input")
              .prop("disabled", true);
          } else {
            $(this).remove();
          }
        });
        $custom_answer_add_row.hide();
      } else {
        $all_other_inputs.prop("disabled", false);
        $all_other_inputs.parent().removeClass("blocked");
        $custom_answer_add_row.show();
        $custom_answers.each(function() {
          $(this)
            .find("input")
            .prop("disabled", false);
        });
      }
    }
  };

  const check_all_inputs = () => {
    $inputs.each(function() {
      const $input = $(this);

      check_if_last_answer_is_excluding($input);

      if ($input.is(":checked")) {
        $input.parent().addClass("checked");
        $input.parents(".form-group").removeClass("unanswered");
        const current_id = $input.attr("id");
        const question_regex = /id_question_(.*?)_/;
        const answer_regex = /id_question_.*?_(.*?)$/;

        if (question_regex.test(current_id) && answer_regex.test(current_id)) {
          const question_id = current_id.match(question_regex)[1];
          const answer_id = current_id.match(answer_regex)[1];

          if (question_visible(question_id)) {
            // gather tags from visible questions only
            update_tags_list(question_id, answer_id);
          }
        }
      } else {
        $input.parent().removeClass("checked");
      }
    });
  };

  const resize_textarea = () => {
    $(".questionnaire__inputs").each(function() {
      const field = $(this).find("textarea");
      field.removeAttr("rows");
      field.on("input", function() {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
      });
      field.on("blur", function() {
        if (field.val().length > 0) {
          field.addClass("filled");
        }
      });
    });
  };

  $inputs.change(function() {
    reset_tags_list();
    check_all_inputs();
    update_form("animate");
    update_exit_popup();
  });

  add_number_to_form_headers();
  reset_tags_list();
  reset_tags_dependencies();
  check_all_inputs();
  update_form();
  update_exit_popup();
  mark_unanswered_questions();
  manage_custom_answers_buttons();
  manage_custom_predefined_answers();
  resize_textarea();

  /*
   * Showing and hiding question help fields
   */

  const help_class = ".questionnaire__help";
  const help_icon_class = ".questionnaire__help-icon";

  $(help_icon_class).click(function(e) {
    e.preventDefault();
    if ($(this).parent(".form-check-label").length) {
      $(this)
        .parent(".form-check-label")
        .find(help_class)
        .slideToggle(500);
    } else {
      $(this)
        .parents(".form-group")
        .find(help_class)
        .slideToggle(500);
      $(this)
        .parents(".question")
        .find(help_class)
        .slideToggle(500);
    }
    return false;
  });

  $(help_class)
    .find(".close")
    .click(function() {
      const $questionnaire_help = $(this).parents(help_class);
      $questionnaire_help.slideUp(500);
    });

  /*
   * Handling sending forms from modals - needed by IE
   */
  $(document).on("click", 'button[type="submit"]', function() {
    const formId = $(this).attr("data-form");
    if (typeof formId !== "undefined") {
      document.getElementById(formId).submit();
    }
  });

  $(document).on("change", "form:not(.no-auto-invalid) .is-invalid", function() {
    const $currentField = $(this);    
    $currentField.removeClass("is-invalid");
    $currentField.next(".invalid-feedback").toggle();
  });

  $(document).on("click", "#js-go-to-login-button", function() {
    window.open("https://www.gov.pl/web/gov/klauzula-przetwarzania-danych-osobowych-udostepnionych-droga-elektroniczna", "_blank");
  });

  if ($("input[name=final_checks]").val() == 1) {
    $("form.auto-finals").submit();
  }

  // WZ budynek
  $("input[name=question_12409]").change(function() {
    if ($(this).val() == '23031') {
      const characteristicBuildingElement = $('.content.characteristic-building');
      if ($(this).prop('checked'))
      {
	  characteristicBuildingElement.removeClass('d-none');
	  characteristicBuildingElement.find(".row.question .form-group").each(function() {
	      $(this).addClass('requirements-passed');
	  });
      }
      else
      {
	  characteristicBuildingElement.addClass('d-none');
          characteristicBuildingElement.find(".row.question .form-group").each(function() {
	      $(this).removeClass('requirements-passed');
	  });
      }
      update_question_numbers();
    }
    if ($(this).val() == '23075') {
      const characteristicConstructionElement = $('.content.characteristic-construction');
      if ($(this).prop('checked'))
      {
	  characteristicConstructionElement.removeClass('d-none');
	  characteristicConstructionElement.find(".row.question .form-group").each(function() {
	      $(this).addClass('requirements-passed');
	  });
      }
      else
      {
	  characteristicConstructionElement.addClass('d-none');
          characteristicConstructionElement.find(".row.question .form-group").each(function() {
	      $(this).removeClass('requirements-passed');
	  });
      }
      update_question_numbers();
    }
  });
  
  $('#investors-form').submit(function (e) {  
    if ($('#js-interactive-map-search-container').is(':visible')) {      
      e.preventDefault();
      return false;
    }
    return true;
  });
  
  $('input[type=number]').each(function () {
    $(this).attr("onkeypress", "return (/[0-9,]/.test(String.fromCharCode(event.which))) ? true : false");
  });

  $('input[all-options-required=""]').each(function () {
    var $p = $(this).parent().parent().parent().closest('.form-group');
    $p.addClass('error');
  });
  
  var not_applicant = ((typeof(is_applicant_persion) == 'undefined') || (!is_applicant_persion));
  if (not_applicant) {
    $('#investors-form-items .investors-form').each(function (i) {
      if ($(this).find('.is-person :radio:checked').length == 0)
        return;
      if (i == 0) {
        $(this).find('.is-person :radio:not(:checked)').attr('disabled', true);
      } else if (!($(this).find('#id_investors-' + i + '-id').val())) {
        $(this).find('.is-person :radio:not(:checked)').attr('disabled', true);
      }
    });
  }
  
  $('#buildings-form-items .check-others-b .form-check-label').last().change(function () {         
    var $el = $(this);
    if ($el.hasClass('checked')) {
      $('#buildings-form-items .others-b').show();      
    } else {
      $('#buildings-form-items .others-b').hide();      
    }
  }).change();
  
  $('#notbuildings-form-items .check-others-c .form-check-label').last().change(function () {         
    var $el = $(this);
    if ($el.hasClass('checked')) {
      $('#notbuildings-form-items .others-c').show();      
    } else {
      $('#notbuildings-form-items .others-c').hide();      
    }
  }).change();

  $(document).ready(function() {    
    init_ter_tree($('body'));
    $('#closeTerModal').click(function () {
      $('#terrother input').removeClass('error');
      var selected = $('#jstree').jstree("get_selected");
      if (selected.includes('14_N')) {
        if ($('#terrother input').val().length == 0) {
          $('#terrother input').addClass('error');
          return;
        }
      }
      $('#terrother input').val('');
      $('#terModal').modal('hide');    
    });
  });
};

export default manage_form;
