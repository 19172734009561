/*
 * Gauge meter arrow control
 */

const navbar_toggling = () => {
  let menu_opened = false;
  $(".navbar-toggler").click(() => {
    $("body,html").toggleClass("body--fixed");

    if (menu_opened) {
      menu_opened = false;
      $("body").unbind("touchmove");
    } else {
      $(".navbar-nav").show();
      menu_opened = true;
      $("body").on("touchmove", function(event) {
        event.preventDefault();
        event.stopPropagation();
      });
    }
  });

  $(".navbar__back-button").click(() => {
    $(".navbar-nav").show();
  });
};

export default navbar_toggling;
