import { countFormsToDelete } from "./count_fields_to_remove.js";
import handleFileInputs from "./handle_file_inputs.js";
import { deleteFormEntry, deleteFormEntryAsk } from "./delete_form.js";
import handle_investors_fields from "./investors_form.js";
import handleInvestorsCopyField from "./handle_investors_copy.js";

const manageMultipleDynamicInputs = function(params) {
  const formsElement = document.getElementById(params.formsElementId);
  const addFormButton = document.getElementById(params.addFormButtonId);
  const totalFormsInputElement = document.getElementById(
    params.totalFormsInputElementId,
  );
  const maxFormsInputElement = document.getElementById(
    params.maxFormsInputElementId,
  );

  function addForm(params) {
    const formItems = document.getElementsByClassName(params.formItemClassName);

    if ((formItems.length == 1) && (!$(formItems[0]).is(':visible'))) {
      $(formItems[0]).show();
      return;
    }
    
    let formNum = formItems.length - 1;
    if (params.beforeClone) {
      params.beforeClone({ itemIndex: formNum + 1 });
    }
    
    const newForm = formItems[formItems.length - 1].cloneNode(true);

    const formRegex = RegExp(/-\d+-/g);
    $(newForm)
      .find(params.dontZeroValueClassName)
      .removeAttr("value");
    $(newForm)
      .find("input")
      .not('[type="hidden"], input[name$=-is_person], .form-check-input')
      .attr("value", "");
    $(newForm)
      .find(".is-person.radio")
      .removeClass("disabled");
    $(newForm)
      .find("input[name$=-is_person]")
      .prop("disabled", false);
    $(newForm)
      .find(".sharing-switch input")
      .prop("checked", false);
    $(newForm)
      .find(".sharing-options")
      .addClass('d-none');
    $(newForm)
      .find(".sharing-form")
      .removeClass('d-none');
    $(newForm)
      .find(".sharing-email-switch input[type=radio][value='True']")
      .prop("checked", true);
    $(newForm)
      .find("a")
      .not(".copy-investors")
      .attr("href", "")
      .html("");    

    const showInteractiveMapButton = newForm.querySelector(
      ".js-show-interactive-map",
    );

    if (showInteractiveMapButton) {
      showInteractiveMapButton.dataset.lotIndex = formNum + 1;
    }

    const $checkedInputs = $(newForm).find(".checked");
    $checkedInputs
      ? $checkedInputs.each(() => {
          const $checkbox = $(this);
          $checkbox.removeClass("checked");
          $checkbox.prop("checked", false);
        })
      : "";

    const $hiddenIdInput = $(newForm).find("input[name$=-id]");
    $hiddenIdInput ? $hiddenIdInput.removeAttr("value") : "";

    const $hiddenConsentInput = $(newForm).find(
      ".js-electronic-mailing-consent input",
    );
    const $visibleConentInput = $(newForm).find(
      ".js-electronic_mailing_consent_denial_user_input checkbox form-check",
    );
    if ($hiddenConsentInput && $visibleConentInput) {
      $hiddenConsentInput.val("True");
      $visibleConentInput.removeClass("checked");
    }

    formNum++;
    newForm.innerHTML = newForm.innerHTML.replaceAll(formRegex, `-${formNum}-`);
    newForm.id = newForm.id.replace(formRegex, `-${formNum}-`);
    newForm.classList.value = newForm.classList.value.replaceAll(
      formRegex,
      `-${formNum}-`,
    );

    if (params.removeElementsFromFormSelector) {
      const itemToRemove = newForm.querySelector(
        params.removeElementsFromFormSelector,
      );
      if (itemToRemove) {
        itemToRemove.remove();
      }
    }

    if (newForm.dataset.itemIndex) {
      newForm.dataset.itemIndex = formNum;
    }

    const newFormDeleteButton = newForm.querySelector(".js-delete-form-button");

    if (newFormDeleteButton) {
      if ($(newFormDeleteButton).hasClass('needs-confirmation'))
        newFormDeleteButton.addEventListener("click", deleteFormEntryAsk);
      else              
        newFormDeleteButton.addEventListener("click", deleteFormEntry);
    }

    const deleteFormElement = newForm.querySelector(
      params.deleteFormElementSelector,
    );

    if (deleteFormElement) {
      const countFormsToDeleteFunction = function(params) {
        countFormsToDelete({
          deleteFormElementsClassName: params.deleteFormElementsClassName,
          totalFormsInputElementId: params.totalFormsInputElementId,
          formItemsClassName: params.formItemClassName,
        });
      };
      deleteFormElement.lastElementChild.addEventListener(
        "click",
        countFormsToDeleteFunction,
        false,
      );
    }

    const select2Items = $(newForm).find("select[class*=select2]");
    select2Items.removeAttr("tabindex aria-hidden data-select2-id");
    select2Items.removeClass("select2-hidden-accessible");
    select2Items.empty();
    select2Items.append("<option></option>");
    select2Items.siblings("span").remove();

    formsElement.appendChild(newForm);
    
    $(newForm)
      .find("select")
      .prop('selectedIndex', 0);
    $(newForm)
      .find(".form-check-input")
      .prop('checked', false);
    $(newForm)
      .find(".form-check-label")
      .removeClass('checked');
    
    $(newForm).show();
    totalFormsInputElement.setAttribute("value", `${formNum + 1}`);
    maxFormsInputElement.setAttribute("value", `${formNum + 1}`);

    handleFileInputs();
    if (params.afterClone) {
      params.afterClone({ el: newForm, elIndex: formNum });
    }

    newForm.scrollIntoView({ behavior: "smooth" });
    handleInvestorsCopyField();
    handle_investors_fields(newForm);

    if (params.handleNewLotMapButton) {
      newForm
        .querySelector(".js-show-interactive-map")
        .addEventListener("click", function(e) {
          params.handleNewLotMapButton(e.target.dataset.lotIndex);
        });
    }
    
    if ($('.form-item__attachment').length > 1) {
      $('.form-item__attachment .js-delete-file-button').show();
    } else {
      $('.form-item__attachment .js-delete-file-button').hide();
    }
    
    
    $(newForm).find('.check-others-b .form-check-label').last().change(function () {      
      if ($(this).hasClass('checked')) {
        $(newForm).find('.others-b').show();      
      } else {
        $(newForm).find('.others-b').hide();      
      }
    }).change();
    
    $(newForm).find('.check-others-c .form-check-label').last().change(function () {      
      if ($(this).hasClass('checked')) {
        $(newForm).find('.others-c').show();      
      } else {
        $(newForm).find('.others-c').hide();      
      }
    }).change();        
    
    if (typeof(__updateCountHook) == 'function') {
      __updateCountHook();
    }
  }

  if (totalFormsInputElement) {
    if (addFormButton) {
      addFormButton.addEventListener("click", function() {
        addForm(params);
      });
    }
  }
};

export default manageMultipleDynamicInputs;
