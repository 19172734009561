const initializeSelectInput = function(el) {
  const fieldName = el.querySelector(".js-form-field-name");

  if (!fieldName)
    return;

  const fieldNameInput = fieldName.querySelector("input");
  const fieldNameSelectInput = fieldName.querySelector(
    ".form-item__select-input",
  );
  
  if (typeof(attachment_placeholder) != 'undefined') {
    fieldNameInput.placeholder = attachment_placeholder;
  } else {
    fieldNameInput.placeholder = "Wpisz nazwę załącznika";
  }
  fieldNameInput.style.position = "relative";
  const textInputDeleteButton = document.createElement("button");
  textInputDeleteButton.innerHTML = "&times;";
  textInputDeleteButton.type = "button";
  textInputDeleteButton.classList.add("form-item__remove-text");

  textInputDeleteButton.addEventListener("click", function(e) {
    e.preventDefault();
    fieldNameInput.value = "";
    fieldNameSelectInput.value = "";
    fieldNameSelectInput.classList.remove("form-item__select-input--hidden");
  });

  fieldName.append(textInputDeleteButton);

  function handleEmptyInput() {
    if (this.value === "") {
      fieldNameSelectInput.classList.remove("form-item__select-input--hidden");
    } else {
      fieldNameSelectInput.classList.add("form-item__select-input--hidden");
    }
  }

  function handleTextInput() {
    fieldNameInput.value = fieldNameSelectInput.value;

    if (this.value !== "") {
      this.classList.add("form-item__select-input--hidden");
    }
  }

  if (fieldName && fieldNameInput && fieldNameSelectInput) {
    if (fieldNameInput.value) {
      fieldNameSelectInput.classList.add("form-item__select-input--hidden");
    }

    if (!fieldNameInput.value) {
      fieldNameInput.value = fieldNameSelectInput.value;
    }
    if (fieldNameInput.value === "") {
      fieldNameSelectInput.classList.remove("form-item__select-input--hidden");
    }
    fieldNameInput.addEventListener("input", handleEmptyInput);

    fieldNameSelectInput.addEventListener("change", handleTextInput);
  }
};

// script handles dropdown and text input in attachments page
const formSelectInputHandler = function() {
  const attachmentsItems = document.getElementById("attachments-form-items");
  if (!attachmentsItems) {
    return;
  }
  const formItems = document.querySelectorAll(".form-item");
  formItems.forEach(el => {
    initializeSelectInput(el);
  });
};

export { formSelectInputHandler, initializeSelectInput };
