import { validateEmail, validateFirstLastName } from "./registration.js";

function validateAccountDetailsForm() {
  const changeAccountDetailsForm = document.getElementById(
    "edit-account-details-form",
  );
  const changeAccountDetailsSubmitButton = document.querySelector(
    ".js-submit-change-account-details",
  );

  function validateForm(e) {
    e.preventDefault();

    const userName = document.getElementsByName("first_last_name")[0];
    const userEmail = document.getElementsByName("email")[0];

    const userNameValidationErrorElement = document.querySelector(
      ".js-user-name-validation-error",
    );

    const emailValidationErrorElement = document.querySelector(
      ".js-email-validation-error",
    );

    userNameValidationErrorElement.innerText = "";
    emailValidationErrorElement.innerText = "";

    const userNameValidation = validateFirstLastName(userName.value);
    const emailValidation = (userEmail.value.length) ? validateEmail(userEmail.value) : { message: '', test: true };

    userNameValidationErrorElement.innerText = userNameValidation.message;
    emailValidationErrorElement.innerText = emailValidation.message;

    if (emailValidation.message && emailValidation.message.length)
      $('.js-email-info').hide();
    else
      $('.js-email-info').show();
    
    if (userNameValidation.test && emailValidation.test) {
      changeAccountDetailsForm.submit();
    } else {
      return;
    }
  }

  if (changeAccountDetailsForm && changeAccountDetailsSubmitButton) {
    changeAccountDetailsForm.onsubmit = validateForm;
  }
}

export default validateAccountDetailsForm;
