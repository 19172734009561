/*
 * Managing buy form
 */

const showing_problems_on_buy_forms = () => {
  $(".buy__form")
    .find("input")
    .change(function() {
      $(this)
        .next(".invalid-feedback")
        .slideUp(200);
      if ($(this).attr("type") === "checkbox") {
        $(this)
          .parents(".checkbox")
          .next(".invalid-feedback")
          .slideUp(200);
      }
    });
};

export default showing_problems_on_buy_forms;
