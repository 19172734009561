const manage_documents_form = () => {
  $(".show-form-groups label").on("click", function() {
    $(this)
      .parent()
      .siblings(".hidden-form-groups")
      .slideDown(300);
  });

  $(".hide-form-groups label").on("click", function() {
    $(this)
      .parent()
      .siblings(".hidden-form-groups")
      .slideUp(300);
  });

  const unchosenClass = "fg--unchosen";
  const $radioButtonsGroup = $(".fg--radio-button");

  $radioButtonsGroup.click(function() {
    $(this).removeClass(unchosenClass);
    $radioButtonsGroup.not(this).addClass(unchosenClass);
  });
};

export default manage_documents_form;
