/*
 * Show contract info popup on homepage
 */

const show_contract_info = () => {
  let scrollTop = 0;

  // change body position to prevent scroll
  const changeBodyPosition = newPosition => {
    $("body").css({
      position: newPosition,
    });
  };

  const trackView = contentId => {
    if (window.fbPixel) {
      fbq("track", "ViewContent", {
        content_type: "product",
        content_ids: [contentId],
      });
    }
  };

  $(".show-contract-info-popup").click(function() {
    const slug = $(this).data("contract-option-id");
    $("#" + slug).addClass("show");
    scrollTop = $(window).scrollTop();
    window.location.hash = slug;
    trackView(slug);
  });

  $(".contract-info-popup .close-button").click(function() {
    $(this)
      .parents(".contract-info-popup")
      .removeClass("show");
    changeBodyPosition("static");
    window.location.hash = "";
    $(window).scrollTop(scrollTop);
  });

  $(".contract-info-popup").each(function() {
    const current_id = $(this).attr("id");
    if (window.location.hash === `#${current_id}`) {
      $(this).addClass("show");
      trackView(current_id);
    }
  });
};

export default show_contract_info;
