/*
 * Gauge meter arrow control
 */

const readiness_gauge_meter = () => {
  if ($(".gauge-meter").length > 0) {
    const readiness = $("[data-readiness]").data("readiness");
    const angle = 2.4 * readiness - 120;

    $(".gauge-meter").css({ transform: `rotate(${angle}deg)` });
  }
};

export default readiness_gauge_meter;
