const handleInvestorsCopyField = function() {
  const copyInvestorsClass = ".js-copy-investors-section";
  const $investors = $(".js-investor:visible");

  $investors.each(function() {
    $(this)
      .find(copyInvestorsClass)
      .hide();
  });
  $investors
    .first()
    .find(copyInvestorsClass)
    .show();
};

export default handleInvestorsCopyField;
