/*
 * Handling scrolling anchors
 */

const scrolling_anchors = () => {
  $(".scroll-button").click(function() {
    const scroll_target = $(this).attr("href");
    let scroll_offset = 0;

    if ($(this).is(".scroll-button--how-it-works")) {
      scroll_offset = 60;
    }

    if ($(this).is(".scroll-button--thematic-packages")) {
      scroll_offset = -70;
    }

    $("html, body").animate(
      {
        scrollTop: $(scroll_target).offset().top - scroll_offset,
      },
      500,
    );
  });
};

export default scrolling_anchors;
