import tippy from "tippy.js";

const tooltipMainMenu = function() {
  const template = document.getElementById("logged-in-menu");
  if (template) {
    tippy("#logged-in-menu-trigger", {
      content: template.innerHTML,
      allowHTML: true,
      arrow: false,
      interactive: true,
      touch: true,
      trigger: "click",
    });
  }
};

const tooltipDashboardList = function() {
  const dashboardListActionClass = ".js-action-links";
  const actionLinksListWrapperClass = ".js-action-links__list-wrapper";

  if (document.querySelector(dashboardListActionClass)) {
    tippy(dashboardListActionClass, {
      content(reference) {
        const tooltipContent = reference.querySelector(
          actionLinksListWrapperClass,
        );
        return tooltipContent.innerHTML;
      },
      placement: "bottom",
      allowHTML: true,
      arrow: false,
      interactive: true,
      touch: true,
      trigger: "click",
    });
  }
};

export { tooltipMainMenu, tooltipDashboardList };
