const manage_summary_buttons = () => {
  $(".summary-button__fill").each(function() {
    const $completion_percentage_field = $(this)
      .parent()
      .find(".summary-button__percentage");
    const new_width = $completion_percentage_field.text();
    if (typeof new_width !== "undefined" && new_width !== "0%") {
      $(this).animate(
        {
          width: new_width,
        },
        400,
        function() {
          $(".summary-button__percentage").fadeIn();
        },
      );
    } else {
      $(".summary-button__percentage").fadeIn();
    }
  });

  $(".bookmark-label").click(function() {
    const disableClass = "bookmark-label--inactive";
    $(".bookmark-label").addClass(disableClass);
    $(this).removeClass(disableClass);
    $(".bookmark").hide();
    const bookmarkId = $(this).attr("data-bookmark");
    if (typeof bookmarkId !== "undefined") {
      $(`#${bookmarkId}`).show();
    }
  });

  $(".documents .summary-part").each(function() {
    const number_of_all_docs = $(this).find(".summary-button--document").length;
    const number_of_disabled_docs = $(this).find(
      ".summary-button--document-disabled",
    ).length;
    if (number_of_disabled_docs === number_of_all_docs) {
      $(this).hide();
    }
  });
};

export default manage_summary_buttons;
